import { FC } from 'react';
import { UseInfiniteQueryResult } from 'react-query';
import { ICustomerAddressResponse, ICustomerResponse } from '../../api/dtos/customer';
import { AxiosResponse } from 'axios';
import CustomerSummary from './Overview/CustomerSummary';
import Alerts from '../common/Alerts';

interface ICustomerAddressesProps {
  customer: ICustomerResponse;
  depositAddressesQuery: UseInfiniteQueryResult<AxiosResponse<ICustomerAddressResponse>>;
  setAlerts: (alerts: number) => void;
}

const CustomerAddresses: FC<ICustomerAddressesProps> = ({ customer, depositAddressesQuery, setAlerts }) => {
  return (
    <div className='flex flex-col gap-3'>
      <CustomerSummary
        customer={customer}
        depositAddressesQuery={depositAddressesQuery}
        showAddressSummary
        showDigitalAssetSummary={false}
      />
      <Alerts
        id={encodeURIComponent(customer.customer_id)}
        custId={customer.id}
        type='customer'
        setAlerts={setAlerts}
        entity_type='address'
        showLoading
      />
    </div>
  );
};

export default CustomerAddresses;
