import { FC } from 'react';
import { useRouter } from '../../modules/router/RouterProvider';
import AddressDetailsReport from '../../components/pdf/Address/AddressDetailsReport';
import Page from '../../components/pdf/Page';
import {
  addressApi,
  useAddressGetAddress,
  useAddressGetAddressTagAggregatedIncomingValues,
  useAddressGetAddressTagAggregatedOutgoingValues,
} from '../../api/address';
import { isTokenCurrency } from '../../utils/helpers/currency';
import { useQuery } from 'react-query';
import ErcTokensReport from '../../components/pdf/Address/ErcTokensReport';
import FlowAnalysis from '../../components/pdf/Address/FlowAnalysis';
import CounterpartySummaryReport from '../../components/pdf/Address/CounterpartySummaryReport';
import { useAlertGetAlerts } from '../../api/alert';
import { flattenInfiniteQueryResult } from '../../utils/helpers/react-query.helper';
import AlertsReport from '../../components/pdf/Alerts/AlertsReport';
import { useCommentGetComments } from '../../api/comment';
import CommentsReport from '../../components/pdf/CommentsReport';
import ClassifiersReport from '../../components/pdf/ClassifiersReport';
import { classifierApi } from '../../api/classifier';

const AddressReport: FC = () => {
  const { getParams, getQueryParams } = useRouter();
  const addressId = Number(getParams().identifier || getParams().addressId);
  const currency = Number(getQueryParams().currency);
  const entities = getQueryParams().entities?.split(',');
  const noEntity = !entities || entities.length === 0;

  const shouldShowSection = (entityName: string): boolean => {
    return noEntity || entities.includes(entityName);
  };

  const { data: addressData } = useAddressGetAddress(addressId);
  const { data: tokenSummary } = useQuery(
    ['addressApi.getTokenSummary', addressId],
    () => addressApi.getTokenSummary(addressId),
    {
      enabled: shouldShowSection('addressDetails') && isTokenCurrency(currency),
    }
  );
  const { data: incomingValuesAddress } = useAddressGetAddressTagAggregatedIncomingValues(addressId, {
    enabled: shouldShowSection('flowAnalysis') || shouldShowSection('counterpartySummary'),
  });
  const { data: outgoingValuesAddress } = useAddressGetAddressTagAggregatedOutgoingValues(Number(addressId), {
    enabled: shouldShowSection('flowAnalysis') || shouldShowSection('counterpartySummary'),
  });
  const { data: openAlertsData } = useAlertGetAlerts(
    { id: addressId, type: 'address', status: [0, 2] },
    {
      enabled: shouldShowSection('openAlerts'),
    }
  );
  const { data: resolvedAlertsData } = useAlertGetAlerts(
    {
      id: addressId,
      type: 'address',
      status: [1, 3],
    },
    {
      enabled: shouldShowSection('resolvedAlerts'),
    }
  );
  const { data: commentsData } = useCommentGetComments(
    { id: addressId, type: 'address' },
    {
      enabled: shouldShowSection('addressComments'),
    }
  );
  const { data: classifiers } = useQuery(['classifierApi.getClassifiers'], () =>
    classifierApi.getClassifiers({ limit: 100, rule_type: 1 })
  );
  const [, openAlerts] = flattenInfiniteQueryResult(openAlertsData);
  const [, resolvedAlerts] = flattenInfiniteQueryResult(resolvedAlertsData);
  const [, comments] = flattenInfiniteQueryResult(commentsData);

  const isCounterpartyVisible = (): boolean => {
    return (
      (shouldShowSection('counterpartySummary') &&
        incomingValuesAddress?.data?.counter_party_summary?.length &&
        incomingValuesAddress?.data?.counter_party_summary?.length > 0) ||
      (outgoingValuesAddress?.data?.counter_party_summary?.length &&
        outgoingValuesAddress?.data?.counter_party_summary?.length > 0)
    );
  };
  let part = 1;

  const AddressFooter: FC = () => {
    return <div className='text-3xs text-gray-500'>Address Report: {addressData?.data?.identifier}</div>;
  };

  return (
    <div>
      {shouldShowSection('addressDetails') && (
        <Page footer={<AddressFooter />}>
          {addressData?.data && <AddressDetailsReport addressData={addressData?.data} part={part++} />}
          {tokenSummary?.data?.results && (
            <ErcTokensReport tokenSummary={tokenSummary?.data?.results} currency={currency} part={part++} />
          )}
        </Page>
      )}
      {shouldShowSection('flowAnalysis') &&
        incomingValuesAddress?.data?.counter_party_summary?.length &&
        outgoingValuesAddress?.data?.counter_party_summary?.length && (
          <Page footer={<AddressFooter />}>
            <FlowAnalysis
              inputs={incomingValuesAddress?.data?.counter_party_summary}
              outputs={outgoingValuesAddress?.data?.counter_party_summary}
              part={part++}
            />
          </Page>
        )}

      {isCounterpartyVisible() && (
        <Page footer={<AddressFooter />}>
          <CounterpartySummaryReport
            incoming={incomingValuesAddress?.data?.counter_party_summary}
            outgoing={outgoingValuesAddress?.data?.counter_party_summary}
            part={part++}
          />
        </Page>
      )}
      {shouldShowSection('openAlerts') && openAlerts?.length > 0 && (
        <Page footer={<AddressFooter />}>
          <AlertsReport
            type='address'
            alerts={openAlerts}
            title='Open Alerts'
            identifier={addressData?.data?.identifier}
            part={part++}
          />
        </Page>
      )}
      {shouldShowSection('resolvedAlerts') && resolvedAlerts?.length > 0 && (
        <Page footer={<AddressFooter />}>
          <AlertsReport
            type='address'
            alerts={resolvedAlerts}
            title='Resolved Alerts'
            identifier={addressData?.data?.identifier}
            part={part++}
          />
        </Page>
      )}
      {shouldShowSection('addressComments') && comments?.length > 0 && (
        <Page footer={<AddressFooter />}>
          <CommentsReport
            comments={comments}
            type='address'
            identifier={addressData?.data?.identifier}
            part={part++}
          />
        </Page>
      )}
      {classifiers?.data.results && (
        <Page footer={<AddressFooter />}>
          <ClassifiersReport classifiers={classifiers?.data.results} type='address' />
        </Page>
      )}
    </div>
  );
};

export default AddressReport;
