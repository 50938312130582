import { IFilters, policyTypeCategoryOptions } from './AlertListFilter';
import { BasicAppliedFilter } from '../AppliedFilters';
import { defaultAlertListFilters } from './AlertListFilter';
import DateAppliedFilter from '../AppliedFilters/DateAppliedFilter';
import RiskAppliedFilter from '../AppliedFilters/RiskAppliedFilter';
import { useMemo } from 'react';
import classNames from 'classnames';

interface ICaseDistributionWidgetProps {
  filters: IFilters;
  setFilters: (filters: IFilters) => void;
  count: number;
  selectedAlerts: number[];
  setSelectedAlerts: (addresses: number[]) => void;
  isAllAlertsSelected: boolean;
  setIsAllAlertsSelected: (boolean) => void;
  selectedText: string;
  selectAllAlerts: (boolean) => void;
}

const AlertListAppliedFilter: React.FC<ICaseDistributionWidgetProps> = (props) => {
  const {
    count,
    filters,
    setFilters,
    selectAllAlerts,
    selectedAlerts,
    selectedText,
    setIsAllAlertsSelected,
    setSelectedAlerts,
    isAllAlertsSelected,
  } = props;
  const isFilterApplied = useMemo(() => {
    if (filters.level?.length) return true;
    if (filters.policy_category) return true;
    if (filters.policy_type) return true;
    if (filters.rule_name?.length) return true;
    if (filters.start_time) return true;
    if (filters.end_time) return true;
  }, [filters]);

  return (
    <div
      className={classNames(
        'flex justify-stretch border-t-[1px] bg-gray-50',
        isFilterApplied || selectedAlerts?.length ? 'px-2 py-2.5' : ''
      )}>
      <div className='flex w-full items-center justify-between'>
        <div className='flex flex-wrap items-center'>
          <RiskAppliedFilter risks={filters.level} setRisks={(r) => setFilters({ ...filters, level: r })} />
          <DateAppliedFilter
            label='Created Date'
            from={filters.start_time}
            to={filters.end_time}
            onRemove={() => setFilters({ ...filters, start_time: null, end_time: null })}
          />
          <BasicAppliedFilter
            single
            setValue={() => setFilters({ ...filters, rule_id: null, rule_name: null })}
            label='Rule Name'
            value={[filters.rule_name]}
          />
          <BasicAppliedFilter
            label='Policy Type & Category'
            single
            value={[
              policyTypeCategoryOptions.find(
                (o) =>
                  o.value.split(',')[0] === filters.policy_category &&
                  Number(o.value.split(',')[1]) === filters.policy_type
              )?.label,
            ]}
            setValue={() => {
              setFilters({ ...filters, policy_category: null, policy_type: null });
            }}
          />
        </div>
        {isFilterApplied && (
          <div className='flex items-center'>
            <button
              className='mr-2 text-2xs text-blue-600'
              onClick={() => setFilters(defaultAlertListFilters)}>
              Reset Filters
            </button>
          </div>
        )}
      </div>
      {selectedAlerts?.length > 0 && (
        <div className='flex min-w-[600px] items-center justify-end gap-3 pr-4 text-2xs'>
          <div></div>
          <div>{selectedText}</div>
          {!isAllAlertsSelected && count <= 100 && (
            <button
              className='text-blue-500'
              onClick={() => {
                selectAllAlerts(true);
                setIsAllAlertsSelected(true);
              }}>
              Select all {count} alerts
            </button>
          )}
          <div
            className='cursor-pointer text-blue-500'
            onClick={() => {
              setSelectedAlerts([]);
              setIsAllAlertsSelected(false);
            }}>
            Clear selection
          </div>
        </div>
      )}
    </div>
  );
};

export default AlertListAppliedFilter;
